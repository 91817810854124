import { Outlet, RouterProvider, createBrowserRouter } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import About from "./pages/About";
import ExecutiveTeam from "./pages/ExecutiveTeam";
import ProjectDelivery from "./pages/ProjectDelivery";
import Regulations from "./pages/Regulations";
import Certification from "./pages/Certification";
import ServicePage from "./pages/ServicePage";
import ProjectPage from "./pages/ProjectPage";
import ContactPage from "./pages/ContactPage";
import HsePolicy from "./pages/HsePolicy";


const Layout = () => {
  return (
    <>
      <Navbar />
      <Outlet />
      <Footer />
      {/* <Footer /> */}
    </>
  );
};

const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {path:'/', element:<Home />},
      {path:'/about-us', element:<About />},
      {path:'/executive-team', element:<ExecutiveTeam />},
      {path:'/project-delivery', element:<ProjectDelivery />},
      {path:'/regulations', element:<Regulations />},
      {path:'/hse-policy', element:<HsePolicy />},
      {path:'/certifications', element:<Certification />},
      {path:'/services', element:<ServicePage />},
      {path:'/projects', element:<ProjectPage />},
      {path:'/contact', element:<ContactPage />},
      // {path:'/connect/:id', element:<Connecting />},
      
    ]
  }
])

function App() {
  return (
    <div className="App overflow-x-hidden min-h-screen">
      <div>
        <RouterProvider router={router} />
      </div>
    </div>
  );
}

export default App;
