import React, { useEffect, useRef, useState } from "react";
import { FaBriefcase, FaCalendarCheck } from "react-icons/fa";
import { IoIosPeople } from "react-icons/io";
import { PiCertificateFill } from "react-icons/pi";
import CountUp from "react-countup";

const OurAchievement = () => {
  const [inView, setInView] = useState({
    established: false,
    certifications: false,
    work: false,
    employees: false,
  });

  const establishedRef = useRef(null);
  const certificationsRef = useRef(null);
  const workRef = useRef(null);
  const employeesRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const { id } = entry.target;
            setInView((prevState) => ({
              ...prevState,
              [id]: true,
            }));
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    if (establishedRef.current) observer.observe(establishedRef.current);
    if (certificationsRef.current) observer.observe(certificationsRef.current);
    if (workRef.current) observer.observe(workRef.current);
    if (employeesRef.current) observer.observe(employeesRef.current);

    return () => observer.disconnect();
  }, []);

  return (
    <div className="md:py-20 mx-auto lg:w-10/12">
      <h1 className="head text-3xl ps-8 lg:ps-0 font-medium lg:text-4xl lg:mb-14 mb-6 uppercase">Our Achievements</h1>
      <div className="grid md:grid-cols-2 lg:grid-cols-4 grid-cols-1 lg:gap-y-10 lg:gap-x-10 gap-y-4 gap-x-4 p-8 md:p-0">
        <div className="mx-auto w-full border border-b-8 border-b-red-500 px-6 py-10" ref={establishedRef} id="established">
          <div className="flex font-bold lg:text-xl items-center justify-start gap-1 mb-4 text-blue-900 ">
            <FaCalendarCheck />
            <h1>Established ({inView.established && <CountUp end={1981} duration={3} formattingFn={(num) => num.toString()} />})</h1>
          </div>
          <h1 className="lg:text-lg">Over 43 years and counting.</h1>
        </div>

        <div className="mx-auto w-full border border-b-8 border-b-red-500 px-6 py-10" ref={certificationsRef} id="certifications">
          <div className="flex font-bold lg:text-xl items-center justify-start gap-1 mb-4 text-blue-900 ">
            <PiCertificateFill />
            <h1>Our Certifications ({inView.certifications && <CountUp end={12} duration={3} />}+)</h1>
          </div>
          <h1 className="lg:text-lg">Over 12 Industry Standard Certifications.</h1>
        </div>

        <div className="mx-auto w-full border border-b-8 border-b-red-500 px-6 py-10" ref={workRef} id="work">
          <div className="flex font-bold lg:text-xl items-center justify-start gap-1 mb-4 text-blue-900 ">
            <FaBriefcase />
            <h1>Our Work ({inView.work && <CountUp end={9000} duration={3} formattingFn={(num) => num.toString()} />}+)</h1>
          </div>
          <h1 className="lg:text-lg">More than 9000 large and small projects are completed.</h1>
        </div>

        <div className="mx-auto w-full border border-b-8 border-b-red-500 px-6 py-10" ref={employeesRef} id="employees">
          <div className="flex font-bold lg:text-xl items-center justify-start gap-1 mb-4 text-blue-900 ">
            <IoIosPeople />
            <h1>Employees ({inView.employees && <CountUp end={100} duration={3} />}+)</h1>
          </div>
          <h1 className="lg:text-lg">The team of more than 100 engineers and leading experts.</h1>
        </div>
      </div>
    </div>
  );
};

export default OurAchievement;
