import React from "react";
import one from "../asset/one.jfif";
import two from "../asset/two.jpg";
import three from "../asset/eight.jpg";
import four from "../asset/15.jpg";
import { HeroSlider } from "./HeroSlider";


const CarouselHero = () => {
  const imgs = [
    { url: three, text: "Welcome to OSCC", subtext: "Where steel meets precision and innovation." },
    { url: four, text: "Oil and Gas", subtext: "We Build Strong Foundations for the Energy Sector." },
    { url: one, text: "Storage Tank Farm", subtext: "We Build Robust Infrastructure for Secure Fuel Storage." },
  { url: two, text: "Steel Fabrication", subtext: "We Craft Quality Steel Structures for Diverse Industries." },
  
];

  return (
    <div className="">
      <HeroSlider slides={imgs} />
      
    </div>
  );
};

export default CarouselHero;


