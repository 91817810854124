import React, { useEffect, useRef } from "react";
import { motion, useAnimation, useInView } from "framer-motion";
import { Link } from "react-router-dom";
import comp from "../asset/comp.jfif"
import project3 from "../asset/project3.jfif"

const HsePolicy = () => {
  const ref = useRef(null);
  const isInView = useInView(ref);

  const mainControls = useAnimation();

  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    } else {
      mainControls.start("hidden");
    }
  }, [isInView, mainControls]);
  return (
    <div ref={ref} className="min-h-screen">
      <div className="relative overflow-hidden bg-blue-950 h-[60vh]">
      <div className="w-full h-full absolute top-0 left-0 bg-[#33333396] "></div>
        <motion.h1
          ref={ref}
          variants={{
            hidden: { opacity: 0, x: -75 },
            visible: { opacity: 1, x: 0 },
          }}
          initial="hidden"
          animate={mainControls}
          transition={{ duration: 0.4, delay: 0.4 }}
          className="absolute text-5xl head text-white font-bold transform -translate-y-1/2 top-1/2 lg:left-40 left-12 uppercase"
        >
          HSE Policy
        </motion.h1>
        <img
          className="w-full h-full object-top"
          src={comp}
          alt=""
        />
      </div>
      <div className="p-4 lg:p-8 lg:w-9/12 mx-auto">
        <div className="text-sm breadcrumbs">
          <ul>
            <li>
              <Link to={"/"}>Home</Link>
            </li>
            <li>
              <Link to={"/about-us"}>About Us</Link>
            </li>
            <li>
              <Link to={"/regulations"}>HSE Policy</Link>
            </li>
          </ul>
        </div>
      </div>

      <div className="p-6 lg:w-9/12 mx-auto">
        <div className="flex lg:flex-row-reverse flex-col-reverse lg:gap-20 gap-10">
          <div className="flex flex-col gap-4">
            
            <motion.h1
              ref={ref}
              variants={{
                hidden: { opacity: 0, y: 75 },
                visible: { opacity: 1, y: 0 },
              }}
              initial="hidden"
              animate={mainControls}
              transition={{ duration: 0.4, delay: 0.4 }}
              className="lg:text-lg"
            >
              OSCC Believes that good HSE performance is an integral part of efficient and profitable business management and therefore committed to executing its activities to ensure safe and healthy working environment for all employees and the safety of persons living in the vicinity of the installation.
            </motion.h1>
            <motion.h1
              ref={ref}
              variants={{
                hidden: { opacity: 0, y: 75 },
                visible: { opacity: 1, y: 0 },
              }}
              initial="hidden"
              animate={mainControls}
              transition={{ duration: 0.4, delay: 0.4 }}
              className="lg:text-lg"
            >
              The company’s goal of no major incident in every project will be achieved by maintaining proactive HSE programs designed to prevent Accident and Incidents. To achieve this, ARMECO is guided by the following principles;
            </motion.h1>
            <motion.h1
              ref={ref}
              variants={{
                hidden: { opacity: 0, y: 75 },
                visible: { opacity: 1, y: 0 },
              }}
              initial="hidden"
              animate={mainControls}
              transition={{ duration: 0.4, delay: 0.4 }}
              className="lg:text-lg"
            >
              <ul>
                <li>• HSE is of equal importance as other business activities.</li>
                <li>• No activity shall be carried out unless considered safe.</li>
                <li>• Safeguard the health and safety of all employees, contractors and third parties.</li>
                <li>• Minimize the impact of activities on the environment.</li>
                <li>• Maintain good relationship with host communities and all stakeholders.</li>
                <li>• Enhance security of life and equipment.</li>
                <li>• Belief that “Every job could be safely done”.</li>
                <li>• Effective communication of HSE requirements and results to all stakeholders.</li>
              </ul>
            </motion.h1>

            
          </div>

          {/* **************** */}
          <div className="">

          <motion.div
            ref={ref}
            variants={{
                hidden: { opacity: 0, x: 75 },
                visible: { opacity: 1, x: 0 },
            }}
            initial="hidden"
            animate={mainControls}
            transition={{ duration: 0.4, delay: 0.4 }}
            className="items-center flex gap-10 flex-col p-10 rounded-3xl  bg-blue-100 shadow-sm justify-center"
            >
            <img
              src={require("../asset/logoOsho.png")}
              className="w-full lg:w-[800px]  "
              alt=""
              />
            <h1 className="font-bold text-xl">1981 - 2024</h1>
          </motion.div>
          <h1 className="text-center text-[#999] lg:text-xl text-2xl mt-8 font-bold">Over <span className="text-red-400">4 Decades</span> of Professionalism & Excellence</h1>
              </div>
        </div>
        <div></div>
      </div>
    </div>
  );
};

export default HsePolicy;
