import React, { useEffect, useRef, useState } from "react";
import { motion, useAnimation, useInView } from "framer-motion";
import { Link } from "react-router-dom";
import { FaChevronRight } from "react-icons/fa";
import delivid from "../asset/delividd.mp4"
import eight from "../asset/eight.jpg";
import Projects from "./Projects";
import { FiArrowRight } from "react-icons/fi";


export const VideoPart = () => {
    const ref = useRef(null);
    const isInView = useInView(ref);


    const mainControls = useAnimation();

    useEffect(() => {
        if (isInView) {
            mainControls.start("visible");
        } else {
            mainControls.start("hidden");
        }
    }, [isInView, mainControls]);

    

    return (
        <div
            ref={ref}
            className="flex gap-4 lg:gap-10 lg:ps-20 relative  bg-base-100  overflow-hidden"
        >
            <motion.div
                ref={ref}
                variants={{
                    hidden: { opacity: 0.5, x: 0 },
                    visible: { opacity: 1, x: 0.5 },
                }}
                initial="hidden"
                animate={mainControls}
                transition={{ duration: 0.3, delay: 0.3 }}
                className="h-full hidden absolute flex-col items-start justify-start gap-4 z-20 "
            >
                {/* <div className="h-[7vh] w-[1px] bg-gray-300 "></div> */}
                {/* <h1 className="text-gray-300 text-xl lg:text-3xl font-light">03</h1> */}
                <div className="h-full w-[1px] bg-gray-300 "></div>
            </motion.div>

            <div className="grid  lg:grid-cols-3 gap-8 ">
                <div className=" p-6 lg:p-0 ">
                    <h1 className=" font-bold mb-10 text-lg uppercase hidden">projects</h1>
                    <motion.h1
                        ref={ref}
                        variants={{
                            hidden: { opacity: 0, y: 75 },
                            visible: { opacity: 1, y: 0 },
                        }}
                        initial="hidden"
                        animate={mainControls}
                        transition={{ duration: 0.4, delay: 0.4 }}
                        className="head lg:text-5xl text-4xl text-blue-900 uppercase font-bold my-6"
                    >
                        Over 50 Years Of Leadership In the Steel Fabrication Industry.
                    </motion.h1>
                    <motion.h1
                        ref={ref}
                        variants={{
                            hidden: { opacity: 0, y: 75 },
                            visible: { opacity: 1, y: 0 },
                        }}
                        initial="hidden"
                        animate={mainControls}
                        transition={{ duration: 0.4, delay: 0.4 }}
                        className=""
                    >
                        OSHODE STEEL CONSTRUCTION COMPANY (OSCC) is a leading Engineering, Instrumentation, and Procurement cooperation headquartered in Ibadan, Nigeria. <br /> <br /> Our company boasts a highly skilled team of professionals with extensive practical and theoretical experience in steel fabrication, construction, and related industries.
                    </motion.h1>
                </div>
                <div className="text-white col-span-2">
                
                    <video src={delivid} autoPlay controls loop className="w-full h-[60vh]"></video>
                    <div className="grid grid-cols-2">
                        <div className="bg-red-700 lg:p-8 p-4">
                            <h1 className="lg:text-3xl text-xl head font-bold   mb-3 text-center">Call For a Quote:</h1>
                            <h1 className="lg:text-3xl text-xl head font-medium text-center">+234 813 945 9311</h1>
                        </div>
                        <div className="bg-white lg:p-8 p-4 flex justify-center items-center">
                            <Link to="/contact" className="btn w-full btn-outline rounded-none text-xl border-4 border-blue-900 hover:bg-blue-900 text-blue-900 hover:border-blue-900 h-16 font-bold">Contact Us <FiArrowRight /> </Link>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    );
};
