import React from "react";
import Services from "../components/Services";
import Logos from "../components/Logos";
import Stats from "../components/Stats";
import Projects from "../components/Projects";
import Hero from "../components/Hero";
import { AboutUs } from "../components/AboutUs";
import Management from "../components/Management";
import { Project } from "../components/Project";
import Contact from "../components/Contact";
import HeroSwitch from "../components/HeroSwitch";
import CarouselHero from "../components/CarouselHero";
import OurAchivement from "../components/OurAchivement";
import { CertGrid } from "../components/CertGrid";
import { VideoPart } from "../components/VideoPart";


const Home = () => {
  return (
    <div>
      {/* <HeroSwitch /> */}
      {/* <Hero /> */}
      <CarouselHero />
      <VideoPart />
      {/* <AboutUs /> */}
      <Services />
      <Logos />
      <OurAchivement />
      {/* <Management /> */}
      <Project />
      <div className="md:py-20 py-10  mx-auto w-10/12">
      <h1 className="text head text-4xl mb-12 underline underline-offset-8 ">Awards & Certificates</h1>
      <CertGrid />
      </div>
      {/* <Contact /> */}
      {/* <Stats /> */}
    </div>
  );
};

export default Home;
