import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Autoplay,
  A11y,
  EffectCreative,
} from "swiper/modules";

import "swiper/css";
import "swiper/css/effect-creative";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

export const HeroSlider = ({slides}) => {
  return (
    <Swiper
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      grabCursor={true}
      effect={"creative"}
      creativeEffect={{
        prev: {
          shadow: true,
          translate: [0, 0, -400],
        },
        next: {
          translate: ["100%", 0, 0],
        },
      }}
      modules={[Navigation, Autoplay, Pagination, A11y, EffectCreative]}
      spaceBetween={50}
      slidesPerView={1}
      navigation
      pagination={{ clickable: true }}
      onSlideChange={() => console.log("slide change")}
      onSwiper={(swiper) => console.log(swiper)}
    >
      {slides.map((slide, i) => (
          <SwiperSlide key={i} className="relative ">
            <div className="w-full h-full absolute top-0 left-0 bg-[#33333396] "></div>
          <img className="h-[60vh] lg:h-[100vh] w-full object-bottom" src={slide.url} alt="yy" />
          <div className="absolute transform -translate-y-1/2 right-12 lg:right-40 top-1/2  text-white text-end z-20">
            <h1 className="md:text-7xl text-3xl font-bold text-red-300 mb-4 ">{slide.text}</h1>
            <h1 className="md:text-2xl text-4xl ">{slide.subtext}</h1>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  )
}
