import React, { useEffect, useRef } from "react";
import { motion, useAnimation, useInView } from "framer-motion";
import { Link } from "react-router-dom";
import Stats from "../components/Stats";
import Logos from "../components/Logos";

const About = () => {
    const ref = useRef(null);
  const isInView = useInView(ref);

  const mainControls = useAnimation();

  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, [])

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    } else {
      mainControls.start("hidden");
    }
  }, [isInView, mainControls]);
  return (
    <div ref={ref} className="min-h-screen">
      <div className="relative overflow-hidden bg-blue-950 h-[60vh]">
      <div className="w-full h-full absolute top-0 left-0 bg-[#33333396] "></div>
        <motion.h1 ref={ref}
              variants={{
                hidden: { opacity: 0, x: -75 },
                visible: { opacity: 1, x: 0 },
              }}
              initial="hidden"
              animate={mainControls}
              transition={{ duration: 0.4, delay: 0.4 }} className="absolute text-5xl head text-white font-bold transform -translate-y-1/2 top-1/2 lg:left-40 left-12 uppercase">
          About Us
        </motion.h1>
        <img
          className="w-full h-full"
          src={require("../asset/about1.jpg")}
          alt=""
        />
      </div>
      <div className="p-4 lg:p-8 lg:w-9/12 mx-auto">
        <div className="text-sm breadcrumbs">
          <ul>
            <li>
              <Link to={"/"}>Home</Link>
            </li>
            <li>
              <Link to={"/about-us"}>About Us</Link>
            </li>
          </ul>
        </div>
      </div>

      <div className="p-6 lg:w-9/12 mx-auto">
        <div className="grid lg:grid-cols-2 lg:gap-20 gap-10">
          <div className="flex flex-col gap-4">
            <motion.h1 ref={ref}
              variants={{
                hidden: { opacity: 0, y: 75 },
                visible: { opacity: 1, y: 0 },
              }}
              initial="hidden"
              animate={mainControls}
              transition={{ duration: 0.3, delay: 0.3 }} className="head text-4xl">Established in 1980</motion.h1>
            <motion.h1 ref={ref}
              variants={{
                hidden: { opacity: 0, y: 75 },
                visible: { opacity: 1, y: 0 },
              }}
              initial="hidden"
              animate={mainControls}
              transition={{ duration: 0.4, delay: 0.4 }} className="lg:text-lg">
              OSHODE STEEL CONSTRUCTION COMPANY (OSCC) is a leading Engineering,
              Instrumentation, and Procurement cooperation headquartered in
              Ibadan, Nigeria. Our company boasts a highly skilled team of
              professionals with extensive practical and theoretical experience
              in steel fabrication, construction, and related industries. This
              deep expertise, combined with our problem-solving approach based
              on a professional methodology, enables us to provide unbiased,
              technically sound solutions tailored to our clients' specific
              needs.
            </motion.h1>
            <motion.h1 ref={ref}
              variants={{
                hidden: { opacity: 0, y: 75 },
                visible: { opacity: 1, y: 0 },
              }}
              initial="hidden"
              animate={mainControls}
              transition={{ duration: 0.4, delay: 0.4 }} className="lg:text-lg">
              OSCC's success is driven by its unwavering commitment to
              innovation, continuous improvement, and the pursuit of excellence
              in every aspect of its operations. Our state-of-the-art facilities
              are equipped with cutting-edge technology, enabling us to deliver
              projects with precision, efficiency, and adherence to the highest
              quality standards.
            </motion.h1>
          </div>

          {/* **************** */}
          <motion.div ref={ref}
              variants={{
                hidden: { opacity: 0, x: 75 },
                visible: { opacity: 1, x: 0 },
              }}
              initial="hidden"
              animate={mainControls}
              transition={{ duration: 0.4, delay: 0.4 }} className="items-center flex justify-center">
            <img
              className="w-full h-full"
              src={require("../asset/est.jpg")}
              alt=""
            />
          </motion.div>
        </div>
        <div className="mt-8 flex flex-col gap-8">
          <motion.h1 ref={ref}
              variants={{
                hidden: { opacity: 0, y: 75 },
                visible: { opacity: 1, y: 0 },
              }}
              initial="hidden"
              animate={mainControls}
              transition={{ duration: 0.4, delay: 0.4 }} className="lg:text-lg ">
            Through our international affiliations, OSCC has established a
            strong presence in the Nigerian market and beyond, effectively
            competing with foreign engineering companies in oil and gas and
            related industries. This global reach allows us to leverage best
            practices, cutting-edge technologies, and a broader network of
            expertise, further enhancing our ability to deliver world-class
            solutions. <br /> <br /> At the heart of OSCC's success lies our
            dedication to acting in the best interests of our clients without
            dependence on any particular type or brand of equipment. Our
            problem-solving approach involves employing appropriate techniques
            and ensuring that solutions are technically sound and tailored to
            meet our client's unique requirements.
          </motion.h1>
          <motion.h1 ref={ref}
              variants={{
                hidden: { opacity: 0, y: 75 },
                visible: { opacity: 1, y: 0 },
              }}
              initial="hidden"
              animate={mainControls}
              transition={{ duration: 0.4, delay: 0.4 }} className="lg:text-lg">
            With a proven track record of delivering exceptional results, OSCC
            remains committed to fostering long-term partnerships with our
            clients, positioning ourselves as a trusted and reliable partner in
            the steel fabrication and construction industry.{" "}
          </motion.h1>
        </div>
        <div>
          <Stats />
        </div>
      </div>

      <div className="relative overflow-hidden bg-blue-950 h-[60vh]">
        <h1 className="absolute text-5xl head text-white font-bold top-64 lg:top-80 lg:left-40 left-12">
          CAPABILITY AND EXPERIENCE
        </h1>
        <img
          className="w-full h-full"
          src={require("../asset/cap.jpg")}
          alt=""
        />
      </div>
      <div className="bg-base-200">
      <div className="p-4 lg:p-8 lg:w-9/12 mx-auto pt-8 flex flex-col gap-8">
        <motion.h1 ref={ref}
              variants={{
                hidden: { opacity: 0, y: 75 },
                visible: { opacity: 1, y: 0 },
              }}
              initial="hidden"
              animate={mainControls}
              transition={{ duration: 0.4, delay: 0.4 }} className="lg:text-lg ">
          OSCC boasts an unparalleled combination of extensive industry
          experience and technical expertise in the steel fabrication and
          construction domain. With over 43 years of successful operations, we
          have established ourselves as a leader in the industry, delivering
          more than 5,000 trucks since our inception.
        </motion.h1>
        <motion.h1 ref={ref}
              variants={{
                hidden: { opacity: 0, y: 75 },
                visible: { opacity: 1, y: 0 },
              }}
              initial="hidden"
              animate={mainControls}
              transition={{ duration: 0.4, delay: 0.4 }} className="lg:text-lg">
          Our core competencies lie in providing tailored solutions for the oil
          and gas sector, specializing in tank fabrication. The company's
          state-of-the-art facilities and highly skilled workforce enable it to
          undertake projects of varying complexities, ensuring adherence to the
          highest quality standards and industry regulations.{" "}
        </motion.h1>
        <motion.h1 ref={ref}
              variants={{
                hidden: { opacity: 0, y: 75 },
                visible: { opacity: 1, y: 0 },
              }}
              initial="hidden"
              animate={mainControls}
              transition={{ duration: 0.4, delay: 0.4 }} className="lg:text-lg">
          Having worked with more than 150 different clients all over the world,
          OSCC has a proven track record of collaborating with various agencies
          and organizations, successfully executing numerous projects for
          esteemed clients such as:
          <br />
          <br />
          <ul>
            <li>• BOVAS (over 500 trucks delivered)</li>
            <li>• MATRIX
          (More than 10 trucks delivered)</li>
            <li>• Total (More than 50 trucks
          delivered)</li>
            <li>• GASTAB (over 50 trucks delivered)</li>
            <li>• NNPC (over 50 trucks
          delivered)</li>
          </ul>
          <br />
                 These successful partnerships demonstrate OSCC's ability to
          meet entities' stringent requirements and standards, solidifying its
          reputation as a trusted and reliable partner.{" "}
        </motion.h1>
      </div>
      </div>
      <Logos />
    </div>
  );
};

export default About;
