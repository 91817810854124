import React, { useEffect, useRef, useState } from "react";
import { motion, useAnimation, useInView, useMotionValue } from "framer-motion";
import { Link } from "react-router-dom";
import { MdEmail, MdOutlineAccessTimeFilled } from "react-icons/md";
import { FaPhone } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import emailjs from "@emailjs/browser";

const ContactPage = () => {
  const ref = useRef(null);
  const isInView = useInView(ref);

  const [email, setEmail] = useState("");
  const [text, setText] = useState("");
  const [btnloading, setBtnLoading] = useState(false);

  const mainControls = useAnimation();

    useEffect(() => {

      window.scrollTo(0, 0);
    }, []);

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    } else {
      mainControls.start("hidden");
    }
  }, [isInView, mainControls]);

  const HandleSubmit = (e) => {
    e.preventDefault();
    setBtnLoading(true);

    try {
      emailjs.send(
        "service_qhbu5jq",
        "template_rga82u6",
        {
          Subject: "New message from your website!!!",
          message: text,

          To: "oshodesteelconscompany@gmail.com",

          From: email,
        },
        "zLVjgUqhChoBKcfcE"
      );

      setTimeout(() => {

          setBtnLoading(false);
          alert("Message sent successfully.")
          setText("");
          setEmail("");
        }, 3000)
    } catch (error) {
        console.log(error);
        alert("Error sending message" + error)
    }

  };
  return (
    <div ref={ref} className="min-h-screen ">
      <div className="relative overflow-hidden bg-blue-950 h-[60vh]">
      <div className="w-full h-full absolute top-0 left-0 bg-[#33333396] "></div>
        <motion.h1
          ref={ref}
          variants={{
            hidden: { opacity: 0, x: -75 },
            visible: { opacity: 1, x: 0 },
          }}
          initial="hidden"
          animate={mainControls}
          transition={{ duration: 0.4, delay: 0.4 }}
          className="absolute text-5xl head text-white font-bold transform -translate-y-1/2 top-1/2 lg:left-40 left-12 uppercase"
        >
          Our Contact
        </motion.h1>
        <img
          className="w-full h-full"
          src={require("../asset/contact.avif")}
          alt=""
        />
      </div>
      <div className="p-4 lg:p-8 lg:w-9/12 mx-auto">
        <div className="text-sm breadcrumbs">
          <ul>
            <li>
              <Link to={"/"}>Home</Link>
            </li>
            <li>
              <Link to={"/services"}>Contact</Link>
            </li>
          </ul>
        </div>
      </div>

      <div className="p-6 lg:w-9/12 mx-auto">
        <div className="grid lg:grid-cols-2 lg:gap-20 gap-10">
          <div className="flex flex-col gap-4">
            <motion.h1
              ref={ref}
              variants={{
                hidden: { opacity: 0, y: 75 },
                visible: { opacity: 1, y: 0 },
              }}
              initial="hidden"
              animate={mainControls}
              transition={{ duration: 0.3, delay: 0.3 }}
              className="head text-4xl"
            >
              Contact Info
            </motion.h1>
            <div className="lg:mt-10 mt-6">
              <div>
                <h3 className="font-bold text-lg">Head Office</h3>
                <p className="py-4 flex items-center gap-4">
                  <FaLocationDot /> Klm 9, Methodist Grammar School, Lagos - Ibadan Expy, after, Ibadan 121101, Ogun State, Nigeria
                </p>
                <h3 className="font-bold text-lg">Working Hours</h3>
                <p className="py-4 flex items-center gap-4">
                  <MdOutlineAccessTimeFilled /> Mon - Sat: 9am - 6pm
                </p>
                <h3 className="font-bold text-lg">Email</h3>
                <p className="py-4 flex items-center gap-4">
                  <MdEmail /> oshodesteelconscompany@gmail.com
                </p>
                <h3 className="font-bold text-lg">Phone</h3>
                <p className="py-4 flex items-center gap-4">
                  <FaPhone /> +234 813 945 9311
                </p>
              </div>
            </div>
          </div>

          <div className="grid lg:grid-cols-1 gap-10  ">
          <motion.h1 ref={ref}
              variants={{
                hidden: { opacity: 0, y: 75 },
                visible: { opacity: 1, y: 0 },
              }}
              initial="hidden"
              animate={mainControls}
              transition={{ duration: 0.3, delay: 0.3 }}
              className="head text-4xl">Get in Touch</motion.h1>
            <div className="grid lg:grid-cols-1 lg:gap-20 gap-10 bg-blue-950 text-white">
              <form onSubmit={HandleSubmit} className="flex flex-col p-8 gap-8">
                <div>
                  <h1 className="text-2xl mb-4">Hi 👋! my email is...</h1>
                  <label className="input input-bordered flex bg-[#ffffff3b] items-center gap-2">
                    <input
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      type="email"
                      className="grow "
                      placeholder="seyi1@gmail.com"
                      required
                    />
                  </label>
                </div>
                {/* ******** */}
                {/* <div>
                <h1 className="text-2xl mb-4">and I represent...</h1>
                <label className="label cursor-pointer text-start items-center justify-start gap-2">
    <input type="radio" name="radio-10" className="radio checked:bg-red-500" checked />
    <span className="label-text">An individual</span> 
  </label>

  <label className="label cursor-pointer text-start items-center justify-start gap-2">
    <input type="radio" name="radio-10" className="radio checked:bg-blue-500" checked />
    <span className="label-text">A Company</span> 
  </label>

</div> */}
                {/* ******** */}
                <div>
                  <h1 className="text-2xl mb-4">I'd love to ask about...</h1>
                  <textarea
                    rows={3}
                    placeholder="Whatever your heart desires :)"
                    onChange={(e) => setText(e.target.value)}
                    value={text}
                    className="textarea textarea-bordered  bg-[#ffffff3b] textarea-lg w-full "
                    required
                  ></textarea>
                </div>

                <button type="submit" className="btn text-lg">
                  {btnloading ? (
                    <span className="loading loading-bars loading-sm bg-blue-950"></span>
                  ) : (
                    "Submit"
                  )}
                </button>
              </form>
            </div>

          </div>
          <div className="responsive-map  lg:col-span-2">

            <iframe className="" src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d989.2973098907888!2d3.8775362695781053!3d7.332632329679622!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zN8KwMTknNTcuNSJOIDPCsDUyJzQxLjUiRQ!5e0!3m2!1sen!2sus!4v1716764273447!5m2!1sen!2sus" width="600" height="450"  allowfullscreen loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </div>
        
      </div>
    </div>
  );
};

export default ContactPage;
