import React, { useEffect, useRef } from "react";
import { motion, useAnimation, useInView } from "framer-motion";
import { Link } from "react-router-dom";
import comp from "../asset/comp.jfif"
import project3 from "../asset/project3.jfif"

const Regulations = () => {
  const ref = useRef(null);
  const isInView = useInView(ref);

  const mainControls = useAnimation();

  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    } else {
      mainControls.start("hidden");
    }
  }, [isInView, mainControls]);
  return (
    <div ref={ref} className="min-h-screen">
      <div className="relative overflow-hidden bg-blue-950 h-[60vh]">
      <div className="w-full h-full absolute top-0 left-0 bg-[#33333396] "></div>
        <motion.h1
          ref={ref}
          variants={{
            hidden: { opacity: 0, x: -75 },
            visible: { opacity: 1, x: 0 },
          }}
          initial="hidden"
          animate={mainControls}
          transition={{ duration: 0.4, delay: 0.4 }}
          className="absolute text-5xl head text-white font-bold transform -translate-y-1/2 top-1/2 lg:left-40 left-12 uppercase"
        >
          COMPLIANCE AND REGULATIONS
        </motion.h1>
        <img
          className="w-full h-full object-top"
          src={comp}
          alt=""
        />
      </div>
      <div className="p-4 lg:p-8 lg:w-9/12 mx-auto">
        <div className="text-sm breadcrumbs">
          <ul>
            <li>
              <Link to={"/"}>Home</Link>
            </li>
            <li>
              <Link to={"/about-us"}>About Us</Link>
            </li>
            <li>
              <Link to={"/regulations"}>Compliance and Regulations</Link>
            </li>
          </ul>
        </div>
      </div>

      <div className="p-6 lg:w-9/12 mx-auto">
        <div className="grid lg:grid-cols-2 lg:gap-20 gap-10">
          <div className="flex flex-col gap-4">
            
            <motion.h1
              ref={ref}
              variants={{
                hidden: { opacity: 0, y: 75 },
                visible: { opacity: 1, y: 0 },
              }}
              initial="hidden"
              animate={mainControls}
              transition={{ duration: 0.4, delay: 0.4 }}
              className="lg:text-lg"
            >
              OSCC understands the critical importance of adhering to industry
              regulations and standards, particularly when working with agencies
              and organizations. As such, the company has implemented robust
              processes and protocols to ensure full compliance with all
              relevant requirements.
            </motion.h1>
            <motion.h1
              ref={ref}
              variants={{
                hidden: { opacity: 0, y: 75 },
                visible: { opacity: 1, y: 0 },
              }}
              initial="hidden"
              animate={mainControls}
              transition={{ duration: 0.4, delay: 0.4 }}
              className="lg:text-lg"
            >
              The tanker trailers will be manufactured in strict accordance with
              the Mandatory Conformity Assessment Programme (MANCAP) and SON
              standards for the general industry, which are the
              government-implemented regulations for industrial sectors
            </motion.h1>
            <motion.h1
              ref={ref}
              variants={{
                hidden: { opacity: 0, y: 75 },
                visible: { opacity: 1, y: 0 },
              }}
              initial="hidden"
              animate={mainControls}
              transition={{ duration: 0.4, delay: 0.4 }}
              className="lg:text-lg"
            >
              Our commitment to compliance is further exemplified by our pursuit
            of certifications from reputable organizations such as SON, the
            Nigerian Tankers Builder, and Industrial Fabrication.
            </motion.h1>

            <motion.h1
            ref={ref}
            variants={{
              hidden: { opacity: 0, y: 75 },
              visible: { opacity: 1, y: 0 },
            }}
            initial="hidden"
            animate={mainControls}
            transition={{ duration: 0.4, delay: 0.4 }}
            className="lg:text-lg "
          >
            These certifications testify to the company's dedication to
            upholding the highest quality and safety standards.
          </motion.h1>
          </div>

          {/* **************** */}
          <motion.div
            ref={ref}
            variants={{
              hidden: { opacity: 0, x: 75 },
              visible: { opacity: 1, x: 0 },
            }}
            initial="hidden"
            animate={mainControls}
            transition={{ duration: 0.4, delay: 0.4 }}
            className="items-center flex justify-center"
          >
            <img
              className="w-full h-full"
              src={project3}
              alt=""
            />
          </motion.div>
        </div>
        <div className="mt-8 flex flex-col gap-8">
          
          <motion.h1
            ref={ref}
            variants={{
              hidden: { opacity: 0, y: 75 },
              visible: { opacity: 1, y: 0 },
            }}
            initial="hidden"
            animate={mainControls}
            transition={{ duration: 0.4, delay: 0.4 }}
            className="lg:text-lg"
          >
            To ensure continuous compliance, we will implement rigorous quality
            control processes throughout the project lifecycle, including
            hydrostatic testing and comprehensive inspections. Additionally, our
            company will maintain open communication with the relevant
            institutions, ensuring transparency and adherence to any guidelines
            or requirements.
          </motion.h1>
        </div>
        <div></div>
      </div>
    </div>
  );
};

export default Regulations;
