import React, { useEffect, useRef } from "react";
import { motion, useAnimation, useInView } from "framer-motion";
import { Link } from "react-router-dom";
import { FaChevronRight } from "react-icons/fa";
import eight from "../asset/eight.jpg";
import Projects from "./Projects";

export const Project = () => {
  const ref = useRef(null);
  const isInView = useInView(ref);

  const mainControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    } else {
      mainControls.start("hidden");
    }
  }, [isInView, mainControls]);
  return (
    <div
      ref={ref}
      className="flex gap-4 lg:gap-10 lg:px-20 relative px-4 bg-blue-950 text-white overflow-hidden"
    >
      <motion.div
        ref={ref}
        variants={{
          hidden: { opacity: 0.5, x: 0 },
          visible: { opacity: 1, x: 0.5 },
        }}
        initial="hidden"
        animate={mainControls}
        transition={{ duration: 0.3, delay: 0.3 }}
        className="h-full absolute flex flex-col items-start justify-start gap-4 z-20 "
      >
        {/* <div className="h-[7vh] w-[1px] bg-gray-300 "></div> */}
        {/* <h1 className="text-gray-300 text-xl lg:text-3xl font-light">03</h1> */}
        <div className="h-full w-[1px] bg-gray-300 "></div>
      </motion.div>

      <div className="grid lg:ms-20 ms-8 lg:grid-cols-2  lg:py-20 py-10">
        <div className="h-60 lg:h-full">
          <h1 className=" font-bold mb-10 text-lg uppercase">projects</h1>
          <motion.h1
            ref={ref}
            variants={{
              hidden: { opacity: 0, y: 75 },
              visible: { opacity: 1, y: 0 },
            }}
            initial="hidden"
            animate={mainControls}
            transition={{ duration: 0.4, delay: 0.4 }}
            className="head text-5xl uppercase font-medium   mb-6"
          >
           Completed projects
          </motion.h1>
          <motion.div
            ref={ref}
            variants={{
              hidden: { opacity: 0, y: 75 },
              visible: { opacity: 1, y: 0 },
            }}
            initial="hidden"
            animate={mainControls}
            transition={{ duration: 0.5, delay: 0.6 }}
          >
            <Link to={"/projects"} className="flex items-center gap-8 text-blue-600">
              VIEW PROJECTS <FaChevronRight />{" "}
            </Link>
          </motion.div>
        </div>

        <Projects />

      </div>
    </div>
  );
};
