import React, { useEffect, useRef } from "react";
import { motion, useAnimation, useInView } from "framer-motion";
import { Link } from "react-router-dom";
import { FaChevronRight } from "react-icons/fa";
import one from "../asset/one.jfif";
import procure from "../asset/procure.jpeg";
import fab from "../asset/oil&gas.jfif";
import test from "../asset/project4.jfif";
import brand from "../asset/project3.jfif";
import log from "../asset/project5.jfif";

const Services = () => {
  const ref = useRef(null);
  const isInView = useInView(ref);

  const mainControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    } else {
      mainControls.start("hidden");
    }
  }, [isInView, mainControls]);
  return (
    <div ref={ref} className="flex bg-blue-950 text-white relative gap-4 lg:gap-10 m-0 lg:px-20 px-4 my-0 overflow-hidden ">
      <motion.div
        ref={ref}
        variants={{
          hidden: { opacity: 0.5, x: 0 },
          visible: { opacity: 1, x: 0.5 },
        }}
        initial="hidden"
        animate={mainControls}
        transition={{ duration: 0.3, delay: 0.3 }}
        className="h-full absolute flex flex-col items-start justify-start gap-4 z-20 "
      >
        {/* <div className="h-[7vh] w-[1px] bg-gray-300 "></div>
        <h1 className="text-gray-300 text-xl lg:text-3xl font-light">02</h1> */}
        <div className="h-full  w-[1px] bg-gray-300 "></div>
      </motion.div>

      <div className="flex flex-col lg:ms-20 ms-8 w-full gap-y-2 lg:py-16 py-10">
        <div className="  w-full">
          <h1 className=" font-bold mb-10 text-lg uppercase">
            Our services 
          </h1>
          <div className="w-full flex flex-col lg:flex-row lg:items-center items-start gap-8 lg:gap-0 justify-between ">
            <motion.h1
              ref={ref}
              variants={{
                hidden: { opacity: 0, y: 75 },
                visible: { opacity: 1, y: 0 },
              }}
              initial="hidden"
              animate={mainControls}
              transition={{ duration: 0.4, delay: 0.4 }}
              className="head text-5xl font-medium  lg:text-5xl "
            >
              WHAT WE
              DO
            </motion.h1>
            <motion.div
              ref={ref}
              variants={{
                hidden: { opacity: 0, y: 75 },
                visible: { opacity: 1, y: 0 },
              }}
              initial="hidden"
              animate={mainControls}
              transition={{ duration: 0.5, delay: 0.6 }}
            >
              <Link to={"/services"} className="flex items-center gap-8 text-blue-600">
                READ MORE <FaChevronRight />{" "}
              </Link>
            </motion.div>
          </div>
        </div>

        <div className="lg:mt-8 mt-16">
          <div className="grid grid-cols-2 md:gap-8 gap-4 lg:grid-cols-3">
            {services.map((item, i) => (
            <Link to={"/services"}>
              <motion.div
                ref={ref}
                variants={{
                  hidden: { opacity: 0, y: 75 },
                  visible: { opacity: 1, y: 0 },
                }}
                initial="hidden"
                animate={mainControls}
                transition={{ duration: `${item.duration}`, delay: `${item.duration}` }}
                className="    overflow-hidden"
                key={i}
              >
                <div className="overflow-hidden h-40 md:h-52">

                <img src={item.img} className="w-full hover:scale-110 transition ease-in-out duration-300 h-full " alt="" />
                </div>
                <div className="md:py-6 py-4  text-blue-50">
                  <h1>{item.title}</h1>
                </div>
              </motion.div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;

const services = [
  {
    duration: 0.6,
    img: one,
    title: "Engineering",
  },
  {
    duration: 0.9,
    img: procure,
    title: "Procurement",
  },
  {
    duration: 1.2,
    img: fab,
    title: "Fabrication",
  },
  {
    duration: 1.5,
    img: test,
    title: "QC & Testing",
  },
  {
    duration: 1.8,
    img: brand,
    title: "Branding",
  },
  {
    duration: 2.1,
    img: log,
    title: "Logistics",
  },
];
