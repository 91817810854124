import React, { useEffect, useRef, useState } from "react";
import { motion, useAnimation, useInView, useMotionValue } from "framer-motion";
import { Link } from "react-router-dom";
import test from "../asset/project4.jfif";
import brand from "../asset/project3.jfif";
import log from "../asset/project5.jfif";
import one from "../asset/15.jpg";
import two from "../asset/canopy1.jpg";
import three from "../asset/cap.jpg";
import four from "../asset/deli.jfif";
import five from "../asset/eight.jpg";
import six from "../asset/exe.jfif";
import seven from "../asset/project1.jfif";
import eight from "../asset/serv.jpg";
import nine from "../asset/under1.jpg";
import ten from "../asset/10.jpg";
import project6 from "../asset/project6.jpeg"
import project7 from "../asset/project7.jpeg"
import { Slider } from "../components/Slider";

const ONE_SECOND = 1000;
const AUTO_DELAY = ONE_SECOND * 10;
const DRAG_BUFFER = 50;

const SPRING_OPTIONS = {
  type: "spring",
  mass: 3,
  stiffness: 400,
  damping: 50,
};

const ProjectPage = () => {
  const ref = useRef(null);
  const isInView = useInView(ref);

  const mainControls = useAnimation();

  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    } else {
      mainControls.start("hidden");
    }
  }, [isInView, mainControls]);

  const [imgIndex, setImgIndex] = useState(0);

  const dragX = useMotionValue(0);

  useEffect(() => {
    const intervalRef = setInterval(() => {
      const x = dragX.get();

      if (x === 0) {
        setImgIndex((pv) => {
          if (pv === imgs.length - 1) {
            return 0;
          }
          return pv + 1;
        });
      }
    }, AUTO_DELAY);

    return () => clearInterval(intervalRef);
  }, []);

  const onDragEnd = () => {
    const x = dragX.get();

    if (x <= -DRAG_BUFFER && imgIndex < imgs.length - 1) {
      setImgIndex((pv) => pv + 1);
    } else if (x >= DRAG_BUFFER && imgIndex > 0) {
      setImgIndex((pv) => pv - 1);
    }
  };

  return (
    <div ref={ref} className="min-h-screen ">
      <div className="relative overflow-hidden bg-blue-950 h-[60vh]">
      <div className="w-full h-full absolute top-0 left-0 bg-[#33333396] "></div>
        <motion.h1
          ref={ref}
          variants={{
            hidden: { opacity: 0, x: -75 },
            visible: { opacity: 1, x: 0 },
          }}
          initial="hidden"
          animate={mainControls}
          transition={{ duration: 0.4, delay: 0.4 }}
          className="absolute transform -translate-y-1/2 top-1/2 text-5xl head text-white font-bold  lg:left-40 left-10 uppercase"
        >
          Our Projects
        </motion.h1>
        
        <img
          className="w-full h-full"
          src={require("../asset/project.jpg")}
          alt=""
        />
      </div>
      <div className="p-4 lg:p-8 lg:w-11/12 mx-auto">
        <div className="text-sm breadcrumbs">
          <ul>
            <li>
              <Link to={"/"}>Home</Link>
            </li>
            <li>
              <Link to={"/services"}>Projects</Link>
            </li>
          </ul>
        </div>
      </div>

      <div className="p-6 lg:w-11/12 mx-auto">


        <div className="grid lg:grid-cols-1 lg:gap-20 gap-10">
          <div className="flex flex-col gap-4">
            <motion.h1
              ref={ref}
              variants={{
                hidden: { opacity: 0, y: 75 },
                visible: { opacity: 1, y: 0 },
              }}
              initial="hidden"
              animate={mainControls}
              transition={{ duration: 0.3, delay: 0.3 }}
              className="head text-4xl"
            >
              The Great Work We Did
            </motion.h1>
          </div>
        </div>

        <div className="lg:mt-0 mt-10">
          <div className="relative overflow-hidden  py-8">

          <Slider slides={imgs} />

            {/* <motion.div
              drag="x"
              dragConstraints={{
                left: 0,
                right: 0,
              }}
              style={{
                x: dragX,
              }}
              animate={{
                translateX: `-${imgIndex * 100}%`,
              }}
              transition={SPRING_OPTIONS}
              onDragEnd={onDragEnd}
              className="flex cursor-grab items-center active:cursor-grabbing"
            >
              <Images imgIndex={imgIndex} />
            </motion.div> */}

            {/* <Dots imgIndex={imgIndex} setImgIndex={setImgIndex} /> */}
            {/* <GradientEdges /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectPage;

const imgs = [
  project6,
  project7,
  ten,
  test,
  brand,
  log,
  one,
  two,
  three,
  four,
  five,
  six,
  seven,
  eight,
  nine,
];

const Images = ({ imgIndex }) => {
  return (
    <>
      {imgs.map((imgSrc, idx) => {
        return (
          <motion.div
            key={idx}
            style={{
              backgroundImage: `url(${imgSrc})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
            animate={{
              scale: imgIndex === idx ? 0.95 : 0.85,
            }}
            transition={SPRING_OPTIONS}
            className="aspect-video w-full shrink-0 rounded-none h-96 md:h-full bg-neutral-800 object-cover"
          />
        );
      })}
    </>
  );
};

const Dots = ({ imgIndex, setImgIndex }) => {
  return (
    <div className="mt-4 flex w-full justify-center gap-2">
      {imgs.map((_, idx) => {
        return (
          <button
            key={idx}
            onClick={() => setImgIndex(idx)}
            className={`h-3 w-3 rounded-full transition-colors ${
              idx === imgIndex ? "bg-neutral-500" : "bg-neutral-200"
            }`}
          />
        );
      })}
    </div>
  );
};
