import React, { useEffect, useRef } from "react";
import { motion, useAnimation, useInView } from "framer-motion";
import { Link } from "react-router-dom";
import Rasaq from "../asset/Rasaq.jpg";
import Seyi from "../asset/Seyi.jpg";

const ExecutiveTeam = () => {
  const ref = useRef(null);
  const isInView = useInView(ref);

  const mainControls = useAnimation();

  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, [])

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    } else {
      mainControls.start("hidden");
    }
  }, [isInView, mainControls]);
  return (
    <div ref={ref} className="min-h-screen">
      <div className="relative overflow-hidden bg-blue-950 h-[60vh]">
      <div className="w-full h-full absolute top-0 left-0 bg-[#33333396] "></div>
        <motion.h1
          ref={ref}
          variants={{
            hidden: { opacity: 0, x: -75 },
            visible: { opacity: 1, x: 0 },
          }}
          initial="hidden"
          animate={mainControls}
          transition={{ duration: 0.4, delay: 0.4 }}
          className="absolute text-5xl head text-white font-bold transform -translate-y-1/2 top-1/2 lg:left-40 left-12 uppercase"
        >
          Executive Team
        </motion.h1>
        <img
          className="w-full h-full"
          src={require("../asset/exe.jpg")}
          alt=""
        />
      </div>

      <div className="p-4 lg:p-8 lg:w-9/12 mx-auto">
        <div className="text-sm breadcrumbs">
          <ul>
            <li>
              <Link to={"/"}>Home</Link>
            </li>
            <li>
              <Link to={"/about-us"}>About Us</Link>
            </li>
            <li>
              <Link to={"/executive-team"}>Executive Team</Link>
            </li>
          </ul>
        </div>
      </div>

      <div className="p-6 lg:w-9/12 mx-auto">
        <div className="grid  gap-10 ">
          {management.map((item, i) => (
            <motion.div
              ref={ref}
              variants={{
                hidden: { opacity: 0, y: 75 },
                visible: { opacity: 1, y: 0 },
              }}
              initial="hidden"
              animate={mainControls}
              transition={{
                duration: `${item.duration}`,
                delay: `${item.duration}`,
              }}
              className="bg-white shadow-sm lg:shadow hover:shadow-xl lg:grid lg:grid-cols-2 md:h-80 overflow-hidden"
              key={i}
            >
              <div className="overflow-hidden h-72 md:h-full">
                <img
                  src={item.img}
                  className="w-full hover:scale-110 transition ease-in-out duration-300 h-full "
                  alt=""
                />
              </div>
              <div>
                <div className="md:p-6 p-4 font-semibold text-black">
                  <h1 className="text-md md:text-xl text-blue-950 font-bold">
                    {item.title}
                  </h1>
                  <h1 className="text-gray-500 mt-2 text-sm md:text-lg">
                    {item.desc}
                  </h1>
                </div>
                <div className="md:p-6 p-4">
                  <p className="text-sm md:text-lg">{item.bio}</p>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ExecutiveTeam;

const management = [
  {
    duration: 0.6,
    img: Rasaq,
    title: "Engr Rasaq Oshode",
    desc: "Executive Director",
    bio: "As the founder of OSCC, Engineer Rasaq brings decades of extensive experience in the steel construction industry, providing strategic leadership and oversight to ensure project success.",
  },
  {
    duration: 0.9,
    img: Seyi,
    title: "Engr Seyi Oshode",
    desc: "Managing Director",
    bio: "He combines technical expertise with strong project management and operational skills, ensuring efficient execution and timely delivery.",
  },
];
