import React from 'react'
import cert1 from "../asset/cert1.jpeg";
import cert2 from "../asset/cert2.jpeg";
import cert3 from "../asset/cert3.jpeg";
import cert4 from "../asset/cert4.jpeg";
import cert5 from "../asset/cert5.jpeg";
import cert6 from "../asset/cert6.jpeg";
import cert7 from "../asset/cert7.png";
import cert8 from "../asset/cert8.png";

const imgs = [cert1, cert2, cert3, cert4, cert5, cert6, cert7, cert8];


export const CertGrid = () => {
  return (
    <div className="grid bg-white gap-10 lg:grid-cols-4 grid-cols-3">
              {
                imgs.map((img, i) => (
                  <div className=" overflow-hidden">

                    <img key={i} src={img} className="object-contain" alt="" />
                  </div>
                ))
              }
            </div>
  );
};
