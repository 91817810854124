import React from "react";
import { FaChevronRight } from "react-icons/fa";
import project1 from "../asset/project1.jfif"
import project2 from "../asset/project2.jfif"
import project3 from "../asset/project3.jfif"
import project4 from "../asset/project4.jfif"
import project5 from "../asset/project5.jfif"
import project6 from "../asset/project6.jpeg"
import project7 from "../asset/project7.jpeg"

const Projects = () => {
  return (
    <div>
      <div className="text-white">
          <div className="carousel carousel-center p-4 space-x-4 w-full rounded-box h-full ">
            <div className="carousel-item">
              <img
                src={project6}
                className="rounded-box h-full lg:w-80 w-72"
              />
            </div>
            <div className="carousel-item">
              <img
                src={project7}
                className="rounded-box h-full lg:w-80 w-72"
              />
            </div>
            <div className="carousel-item">
              <img
                src={project1}
                className="rounded-box h-full lg:w-80 w-72"
              />
            </div>
            <div className="carousel-item">
              <img
                src={project2}
                className="rounded-box h-full lg:w-80 w-72"
              />
            </div>
            <div className="carousel-item">
              <img
                src={project3}
                className="rounded-box h-full lg:w-80 w-72"
              />
            </div>
            <div className="carousel-item">
              <img
                src={project4}
                className="rounded-box h-full lg:w-80 w-72"
              />
            </div>
            <div className="carousel-item">
              <img
                src={project5}
                className="rounded-box h-full lg:w-80 w-72"
              />
            </div>
            
          </div>
      </div>
    </div>
  );
};

export default Projects;
