import React from "react";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import bovas from "../asset/bovas.jpg";
import gastab from "../asset/gastab.png";
import oando from "../asset/oando.jfif";
import sao from "../asset/sao.jpg";
import menj from "../asset/menj.png";
import total from "../asset/total.png";
import nnpc from "../asset/nnpc.jfif";

const Logos = () => {
  return (
    <div className="md:py-20  mx-auto w-10/12">
      <div className="grid md:grid-cols-7 grid-cols-3 gap-y-10 gap-x-10 p-8 md:p-0">
        {logos.map((item, i) => (
          <div className="mx-auto " key={i}>
            <img src={item} className="md:h-28 hover:opacity-50  transition ease-in-out duration-300 h-16" alt="" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Logos;

const logos = [bovas, gastab, oando, sao, menj, total, nnpc];
