import React, { useEffect, useRef } from "react";
import { motion, useAnimation, useInView } from "framer-motion";
import { Link } from "react-router-dom";
import deli from "../asset/deli.jfif";
import delivid from "../asset/delivid.mp4"

const ProjectDelivery = () => {
  const ref = useRef(null);
  const isInView = useInView(ref);

  const mainControls = useAnimation();

  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    } else {
      mainControls.start("hidden");
    }
  }, [isInView, mainControls]);
  return (
    <div ref={ref} className="min-h-screen">
      <div className="relative overflow-hidden bg-blue-950 h-[60vh]">
      <div className="w-full h-full absolute top-0 left-0 bg-[#33333396] "></div>
        <motion.h1
          ref={ref}
          variants={{
            hidden: { opacity: 0, x: -75 },
            visible: { opacity: 1, x: 0 },
          }}
          initial="hidden"
          animate={mainControls}
          transition={{ duration: 0.4, delay: 0.4 }}
          className="absolute text-5xl head text-white font-bold transform -translate-y-1/2 top-1/2 lg:left-40 left-12 uppercase"
        >
          Project Delivery
        </motion.h1>
        <img className="w-full h-full" src={deli} alt="" />
        
      </div>

      <div className="p-4 lg:p-8 lg:w-9/12 mx-auto">
        <div className="text-sm breadcrumbs">
          <ul>
            <li>
              <Link to={"/"}>Home</Link>
            </li>
            <li>
              <Link to={"/about-us"}>About Us</Link>
            </li>
            <li>
              <Link to={"/project-delivery"}>Project Delivery</Link>
            </li>
          </ul>
        </div>
      </div>

      <div className="p-6 lg:w-9/12 mx-auto">
        <div className="grid lg:grid-cols-2 lg:gap-20 gap-10">
          <div className="flex flex-col gap-4">
            <motion.h1
              ref={ref}
              variants={{
                hidden: { opacity: 0, y: 75 },
                visible: { opacity: 1, y: 0 },
              }}
              initial="hidden"
              animate={mainControls}
              transition={{ duration: 0.3, delay: 0.3 }}
              className="head text-4xl"
            >
              Project Overview
            </motion.h1>
            <motion.h1
              ref={ref}
              variants={{
                hidden: { opacity: 0, y: 75 },
                visible: { opacity: 1, y: 0 },
              }}
              initial="hidden"
              animate={mainControls}
              transition={{ duration: 0.4, delay: 0.4 }}
              className="lg:text-lg"
            >
              OSHODE STEEL CONSTRUCTION COMPANY (OSCC) is well known for
              designing, fabricating, and constructing 45,000-liter petroleum
              tanker trailers to meet the transportation needs of organizations
              or agencies for the safe and efficient haulage of liquid
              flammables such as gasoline and diesel.
            </motion.h1>
            <motion.h1
              ref={ref}
              variants={{
                hidden: { opacity: 0, y: 75 },
                visible: { opacity: 1, y: 0 },
              }}
              initial="hidden"
              animate={mainControls}
              transition={{ duration: 0.3, delay: 0.3 }}
              className="head font-medium text-2xl"
            >
              Goals and Objective
            </motion.h1>
            <motion.ul ref={ref}
              variants={{
                hidden: { opacity: 0, y: 75 },
                visible: { opacity: 1, y: 0 },
              }}
              initial="hidden"
              animate={mainControls}
              transition={{ duration: 0.4, delay: 0.4 }} className="flex flex-col gap-4">
            <li>•   Provide reliable, high-quality tanker trailer solutions tailored to the specific requirements of government entities involved in transporting and distributing petroleum products</li>
            <li>•   Ensure strict adherence to all relevant industry standards, regulations, and best practices, including the Mandatory Conformity Assessment Programme (MANCAP) and SON 
standards. 
</li>
            <li>•   Leverage OSCC's extensive experience and expertise in steel fabrication and tank construction to deliver tanker trailers that meet the highest quality and safety standards. </li>
            <li>•   Establish a long-term, mutually beneficial partnership with organizations or agencies, positioning OSCC as a trusted supplier of steel fabrication and tanker trailer solutions. </li>
            <li>•   Deliver the tanker trailers within the specified timeline of 3 weeks to one month, ensuring efficient turnaround times without compromising quality or safety. </li>
            <li>•   Offer competitive pricing and flexible payment terms to provide value for money and convenience for government clients.</li>
          </motion.ul>
          </div>

          {/* **************** */}
          <motion.div
            ref={ref}
            variants={{
              hidden: { opacity: 0, x: 75 },
              visible: { opacity: 1, x: 0 },
            }}
            initial="hidden"
            animate={mainControls}
            transition={{ duration: 0.4, delay: 0.4 }}
            className="items-center flex justify-center"
          >
            {/* <img
              className="w-full h-full"
              src={require("../asset/cap.jpg")}
              alt=""
            /> */}
            
        <video className="w-full h-full" autoPlay controls src={delivid}></video>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default ProjectDelivery;
