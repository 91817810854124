import React from "react";

const Stats = () => {
  return (
    <div className="w-full flex justify-center items-center py-12 ">
      <div className="stats md:py-10 modalGrad text-center mx-auto  stats-vertical lg:stats-horizontal shadow-lg ">
        <div className="stat py-10">
          {/* <div className="stat-title">Downloads</div> */}
          <div className="stat-value text-5xl font-medium">43+</div>
          <div className="stat-desc md:text-lg font-bold mt-4 ">years of experience in the construction field</div>
        </div>

        <div className="stat py-10">
          
          <div className="stat-value text-5xl font-medium">600+</div>
          <div className="stat-desc md:text-lg font-bold mt-4 ">construction and steel fabrication projects</div>
        </div>

        <div className="stat py-10">
          
          <div className="stat-value text-5xl font-medium">150+</div>
          <div className="stat-desc md:text-lg font-bold mt-4 ">clients around the world</div>
        </div>
      </div>
    </div>
  );
};

export default Stats;
