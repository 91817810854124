import React, { useState } from "react";
import { FaFacebook, FaInstagram, FaLinkedin, FaYoutube } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FiArrowRight } from "react-icons/fi";
import { Link } from "react-router-dom";
import emailjs from "@emailjs/browser";

const Footer = () => {
  const [email, setEmail] = useState("");
  const [btnloading, setBtnLoading] = useState(false);

  const HandleSubmit = (e) => {
    e.preventDefault();
    setBtnLoading(true);

    try {
      emailjs.send(
        "service_qhbu5jq",
        "template_rga82u6",
        {
          Subject: "New Subscriber!!!",
          message: email,

          To: "oshodesteelconscompany@gmail.com",

          From: email,
        },
        "zLVjgUqhChoBKcfcE"
      );

      setTimeout(() => {

          setBtnLoading(false);
          alert("Message sent successfully.")
          
          setEmail("");
        }, 3000)
    } catch (error) {
        console.log(error);
        alert("Error sending message" + error)
    }

  };

  return (
    <div className="relative bg-white">
      
<div className="absolute -top-20 lg:left-20 left-4 p-10 z-30  bg-red-700 rounded-r-3xl shadow-2xl">
<h1 className="text head text-white text-4xl lg:text-5xl mb-4 ">Subscribe</h1>
<form onSubmit={HandleSubmit} className="flex flex-col gap-4 lg:w-[50vw] w-[75vw]">
  <input onChange={(e) => setEmail(e.target.value)}
                      value={email} className="input p-6 rounded-none" type="text" placeholder="Email" required />
  <button type="submit" className="btn bg-blue-900 hover:bg-blue-950 text-white border-none rounded-none text-lg">
  {btnloading ? (
                    <span className="loading loading-bars loading-sm bg-blue-950"></span>
                  ) : (
                    "Subscribe"
                  )} </button>
</form>
</div>
    <div className="relative foot mt-40 pt-40 lg:px-20 px-4 overflow-hidden">
      <div className="h-full absolute flex flex-col items-start justify-start gap-4 z-20 ">
        <div className="h-full  w-[1px] bg-gray-400 "></div>
      </div>

      {/* <div className="absolute md:bottom-16 -right-40">
        <img
          src={require("../asset/logoOsho.png")}
          className="w-80 h-80 opacity-5"
          alt=""
        />
      </div>
      <div className="absolute bottom-16 -left-40">
        <img
          src={require("../asset/logoOsho.png")}
          className="w-80 h-80 opacity-5"
          alt=""
        />
      </div> */}

      <div className="lg:ms-20 ms-0 pb-4 lg:pt-10  text-white">
        {/* <footer className="footer  p-10  ">
          <nav>
            <h6 className="footer-title">Services</h6>
            <Link to={"/services"} className="link link-hover">
              Engineering
            </Link>
            <Link to={"/services"} className="link link-hover">
              Procurement
            </Link>
            <Link to={"/services"} className="link link-hover">
              Fabrication
            </Link>
            <Link to={"/services"} className="link link-hover">
              QC & Testing
            </Link>
            <Link to={"/services"} className="link link-hover">
              Branding
            </Link>
            <Link to={"/services"} className="link link-hover">
              Logistics
            </Link>
          </nav>
          <nav>
            <h6 className="footer-title">Company</h6>
            <Link to={"/about-us"} className="link link-hover">
              About us
            </Link>
            <Link to={"/services"} className="link link-hover">
              Services
            </Link>
            <Link to={"/projects"} className="link link-hover">
              Projects
            </Link>
            <Link to={"/contact"} className="link link-hover">
              Contact
            </Link>
          </nav>
          <nav>
            <h6 className="footer-title">Legal</h6>
            <Link className="link link-hover">Terms of use</Link>
            <Link className="link link-hover">Privacy policy</Link>
            <Link className="link link-hover">Cookie policy</Link>
          </nav>
        </footer> */}
        <footer className="footer px-10 lg:py-3 py-6">
          <aside className="items-center ">
            <img
              src={require("../asset/logoOsho.png")}
              className="w-32  "
              alt=""
            />
            <p className="text-lg">
              Oshode Steel Construction Company.
              <br />
              All Rights Reserved | ©2024 OSCC
            </p>
          </aside>
          <nav className="md:place-self-start md:justify-self-end">
            <div className="grid grid-flow-col items-center gap-4">
            <h1 className="lg:text-lg opacity-70">Follow Us:</h1>
              <Link to={"https://www.facebook.com/oshodesteelconscompany/"}>
                <FaFacebook size={24} />
              </Link>
              <Link
                to={
                  "https://www.linkedin.com/in/oshode-steel-construction-company-limited-5230881b6/"
                }
              >
                <FaLinkedin size={24} />
              </Link>
              <Link to={"https://twitter.com/oshode_steel"}>
                <FaXTwitter size={24} />
              </Link>
              <Link to={"https://www.instagram.com/oshode_steel_cons_ltd/?e=7f6bf33e-2ae7-4482-94ea-a35a1d38d01d&g=5"}>
                <FaInstagram size={24} />
              </Link>
              <Link to={"https://www.youtube.com/results?search_query=oshode+steel"}>
                <FaYoutube size={24} />
              </Link>
            </div>
          </nav>
        </footer>
      </div>
    </div>
    </div>
  );
};

export default Footer;
