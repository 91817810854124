import React, { useEffect, useRef } from "react";
import { motion, useAnimation, useInView } from "framer-motion";
import { Link } from "react-router-dom";
import project3 from "../asset/project2.jfif";

import { Slider } from "../components/Slider";
import { SliderCert } from "../components/SliderCert";
import { CertGrid } from "../components/CertGrid";

const Certification = () => {
  const ref = useRef(null);
  const isInView = useInView(ref);

  const mainControls = useAnimation();

  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    } else {
      mainControls.start("hidden");
    }
  }, [isInView, mainControls]);
  return (
    <div ref={ref} className="min-h-screen">
      <div className="relative overflow-hidden bg-blue-950 h-[60vh]">
        <div className="w-full h-full absolute top-0 left-0 bg-[#33333396] "></div>
        <motion.h1
          ref={ref}
          variants={{
            hidden: { opacity: 0, x: -75 },
            visible: { opacity: 1, x: 0 },
          }}
          initial="hidden"
          animate={mainControls}
          transition={{ duration: 0.4, delay: 0.4 }}
          className="absolute text-5xl head text-white font-bold transform -translate-y-1/2 top-1/2 lg:left-40 left-12 uppercase"
        >
          certifications and Accreditations
        </motion.h1>
        <img
          className="w-full h-full "
          src={require("../asset/certification.jpeg")}
          alt=""
        />
      </div>
      <div className="p-4 lg:p-8 lg:w-9/12 mx-auto">
        <div className="text-sm breadcrumbs">
          <ul>
            <li>
              <Link to={"/"}>Home</Link>
            </li>
            <li>
              <Link to={"/about-us"}>About Us</Link>
            </li>
            <li>
              <Link to={"/regulations"}>Certifications and Accreditations</Link>
            </li>
          </ul>
        </div>
      </div>

      <div className="p-6 lg:w-9/12 mx-auto">
        <div className="flex flex-col-reverse lg:gap-20 gap-10">
          <div className="flex flex-col gap-4">
            <motion.h1
              ref={ref}
              variants={{
                hidden: { opacity: 0, y: 75 },
                visible: { opacity: 1, y: 0 },
              }}
              initial="hidden"
              animate={mainControls}
              transition={{ duration: 0.4, delay: 0.4 }}
              className="lg:text-lg"
            >
              OSCC holds certifications from reputable organizations, including
              SON, the Nigerian Tankers Builder, and Industrial Fabrication,
              among others. These certifications prove the company's adherence
              to industry standards and regulations, further reinforcing its
              commitment to quality and compliance.
            </motion.h1>
            <motion.h1
              ref={ref}
              variants={{
                hidden: { opacity: 0, y: 75 },
                visible: { opacity: 1, y: 0 },
              }}
              initial="hidden"
              animate={mainControls}
              transition={{ duration: 0.4, delay: 0.4 }}
              className="lg:text-lg"
            >
              OSCC was the first company to receive SON certification in Ibadan,
              a testament to our pioneering spirit and dedication to setting the
              highest industry standards. This achievement underscores our
              commitment to quality and adherence to industry regulations,
              positioning the company as a trailblazer in the steel fabrication
              and construction sector
            </motion.h1>
          </div>

          {/* **************** */}
          <motion.div
            ref={ref}
            variants={{
              hidden: { opacity: 0, x: 75 },
              visible: { opacity: 1, x: 0 },
            }}
            initial="hidden"
            animate={mainControls}
            transition={{ duration: 0.4, delay: 0.4 }}
            className="items-center   flex justify-center"
          >
            {/* <SliderCert slides={imgs} /> */}

            <CertGrid  />
            
            
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Certification;

