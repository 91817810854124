import React, { useEffect, useRef, useState } from "react";
import { motion, useAnimation, useInView } from "framer-motion";
import { Link } from "react-router-dom";
import one from "../asset/one.jfif";
import procure from "../asset/procure.jpeg";
import fab from "../asset/oil&gas.jfif";
import test from "../asset/project4.jfif";
import brand from "../asset/project3.jfif";
import log from "../asset/project5.jfif";

const ServicePage = () => {
  const ref = useRef(null);
  const isInView = useInView(ref);
  const [selected, setSelected] = useState("")

  const mainControls = useAnimation();

  const handleClick = (item) => {
    document.getElementById('my_modal_2').showModal();
    setSelected(item)
  }

  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    } else {
      mainControls.start("hidden");
    }
  }, [isInView, mainControls]);
  return (
    <div ref={ref} className="min-h-screen">
      <div className="relative overflow-hidden bg-blue-950 h-[60vh]">
      <div className="w-full h-full absolute top-0 left-0 bg-[#33333396] "></div>
        <motion.h1
          ref={ref}
          variants={{
            hidden: { opacity: 0, x: -75 },
            visible: { opacity: 1, x: 0 },
          }}
          initial="hidden"
          animate={mainControls}
          transition={{ duration: 0.4, delay: 0.4 }}
          className="absolute text-5xl head text-white font-bold transform -translate-y-1/2 top-1/2 lg:left-40 left-12 uppercase"
        >
          Our Services
        </motion.h1>
        <img
          className="w-full h-full object-bottom"
          src={require("../asset/serv.jpg")}
          alt=""
        />
      </div>
      <div className="p-4 lg:p-8 lg:w-9/12 mx-auto">
        <div className="text-sm breadcrumbs">
          <ul>
            <li>
              <Link to={"/"}>Home</Link>
            </li>
            <li>
              <Link to={"/services"}>Services</Link>
            </li>
            
          </ul>
        </div>
      </div>

      <div className="p-6 lg:w-9/12 mx-auto">
        <div className="grid lg:grid-cols-1 lg:gap-20 gap-10">
          <div className="flex flex-col gap-4">
          <motion.h1
              ref={ref}
              variants={{
                hidden: { opacity: 0, y: 75 },
                visible: { opacity: 1, y: 0 },
              }}
              initial="hidden"
              animate={mainControls}
              transition={{ duration: 0.3, delay: 0.3 }}
              className="head text-4xl"
            >
              What we do
            </motion.h1>
            <motion.h1
              ref={ref}
              variants={{
                hidden: { opacity: 0, y: 75 },
                visible: { opacity: 1, y: 0 },
              }}
              initial="hidden"
              animate={mainControls}
              transition={{ duration: 0.4, delay: 0.4 }}
              className="lg:text-lg"
            >
              OSCC boasts an unparalleled combination of extensive industry experience and technical expertise 
in the steel fabrication and construction domain. With over 43 years of successful operations, we 
have established ourselves as a leader in the industry, delivering more than 5,000 trucks since our
inception. <br /> <br />
Our core competencies lie in providing tailored solutions for the oil and gas sector, specializing in 
tank fabrication. The company's state-of-the-art facilities and highly skilled workforce enable it to 
undertake projects of varying complexities, ensuring adherence to the highest quality standards 
and industry regulations.
  
            </motion.h1>
            
          </div>          
        </div>
        
        <div className="lg:mt-8 mt-16">
          <div className="grid grid-cols-2 md:gap-8 gap-4 lg:grid-cols-3">
            {services.map((item, i) => (
            
              <motion.div
                ref={ref}
                variants={{
                  hidden: { opacity: 0, y: 75 },
                  visible: { opacity: 1, y: 0 },
                }}
                initial="hidden"
                animate={mainControls}
                transition={{ duration: `${item.duration}`, delay: `${item.duration}` }}
                className="border-b cursor-pointer overflow-hidden"
                key={i}
                onClick={() => handleClick(item)}
              >
                <div className="overflow-hidden h-40 md:h-52">

                <img src={item.img} className="w-full hover:scale-110 transition ease-in-out duration-300 h-full " alt="" />
                </div>
                <div className="md:p-6 py-4 lg:text-xl">
                  <h1>{item.title}</h1>
                </div>

                
              </motion.div>

              
            ))}
          </div>
        </div>

        <motion.h1
              ref={ref}
              variants={{
                hidden: { opacity: 0, y: 75 },
                visible: { opacity: 1, y: 0 },
              }}
              initial="hidden"
              animate={mainControls}
              transition={{ duration: 0.4, delay: 0.4 }}
              className="lg:text-lg my-10"
            >
              OSCC will leverage its extensive experience, skilled workforce, and adherence to industry best 
practices to ensure that the tanker trailers are manufactured to the highest standards, meeting or 
exceeding the requirements set forth by organizations or agencies.
            </motion.h1>
      </div>

      {/* Open the modal using document.getElementById('ID').showModal() method */}
{/* <button className="btn" onClick={()=>document.getElementById('my_modal_2').showModal()}>open modal</button> */}

<dialog id="my_modal_2" className="modal">
  <div className="modal-box">
    <h3 className="font-bold text-lg">{selected.title}</h3>
    <p className="py-4">{selected.text}</p>
  </div>
  <form method="dialog" className="modal-backdrop">
    <button>close</button>
  </form>
</dialog>

    </div>
  );
};

export default ServicePage;

const services = [
    {
      duration: 0.6,
      img: one,
      title: "Engineering",
      text: "Detailed design and engineering of the tanker trailer specifications, incorporating client requirements and industry standards.",
    },
    {
        duration: 0.9,
        img: procure,
        title: "Procurement",
        text: "Procurement of high-quality materials, components, and equipment from reliable suppliers.",
    },
    {
        duration: 1.2,
        img: fab,
        title: "Fabrication",
        text: " Fabrication and assembly of the tanker trailer components, including the tank, chassis, suspension system, and safety features, using state-of-the-art facilities and advanced manufacturing techniques.",
    },
    {
        duration: 1.5,
        img: test,
        title: "QC & Testing",
        text: "Rigorous quality control and testing procedures, including hydrostatic testing, to ensure compliance with MANCAP, SON standards, and client specifications.",
    },
    {
        duration: 1.8,
        img: brand,
        title: "Branding",
        text: "Professional painting and finishing, including applying the client's logo or branding.",
    },
    {
        duration: 2.1,
        img: log,
        title: "Logistics",
        text: " The completed tanker trailers are delivered to the specified location, along with all necessary documentation and certifications.",
    },
  ];