import React, { useState } from "react";
import { FaEnvelope, FaPhone, FaPhoneAlt, FaSearch } from "react-icons/fa";
import { MdOutlineAccessTimeFilled } from "react-icons/md";
import { Link, useLocation } from "react-router-dom";

const Navbar = () => {
  const [active, setActive] = useState(false);
  const location = useLocation()

  const isActivePath = (path) => {
    return location.pathname === path;
  }

  window.addEventListener("scroll", () => {
    if (window.scrollY > 70) {
      setActive(true);
    } else {
      setActive(false);
    }
  });
  return (
    <div
      className={`${
        active === true ? " " : " "
      } transition  relative  lg:pt-4  z-50 w-full`}
    >
      <div className="navbar-center md:flex text-white justify-center items-center bg-blue-950 lg:hidden gap-6 p-4 text-sm">
          <h1 className="flex items-center my-1 gap-2 font-medium">
            {" "}
            <FaPhoneAlt />
            +234 701 828 1060
          </h1>
          <h1 className="flex items-center my-1 gap-2 font-medium">
            {" "}
            <FaEnvelope />
            oshodesteelconscompany@gmail.com
          </h1>
          <h1 className="flex items-center my-1 gap-2 font-medium">
            {" "}
            <MdOutlineAccessTimeFilled />
            Mon - Sat: 9am - 6pm
          </h1>
        </div>
      <div className="navbar mb-4 text-[#333]">
        <div className="navbar-start px-6">
          
          <Link to={"/"} className="flex flex-col lg:items-center gap-1 ">
            <img
              src={require("../asset/logoOsho.png")}
              className="md:w-20 w-14"
              alt="OSCC Logo png"
            />
            <h1 className="font-bold text-xs text-[#999] ">OSHODE STEEL CONS COMPANY</h1>
          </Link>
        </div>
        <div className="navbar-center hidden lg:flex gap-6 p-2">
          <h1 className="flex items-center gap-2 font-medium">
            {" "}
            <FaPhoneAlt />
            +234 701 828 1060
          </h1>
          <h1 className="flex items-center gap-2 font-medium">
            {" "}
            <FaEnvelope />
            oshodesteelconscompany@gmail.com
          </h1>
          <h1 className="flex items-center gap-2 font-medium">
            {" "}
            <MdOutlineAccessTimeFilled />
            Mon - Sat: 9am - 6pm
          </h1>
        </div>
        <div
          className={`navbar-end px-6`}
        >
          <div className="dropdown">
            <div
              tabIndex={0}
              role="button"
              className={`${
                active === true ? " " : ""
              } btn btn-ghost lg:hidden`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-9 w-9"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h8m-8 6h16"
                />
              </svg>
            </div>
            <ul
              tabIndex={0}
              className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-base-100 text-gray-800 rounded-box right-0 w-52"
            >
              <li>
                <Link to={"/"}>Home</Link>
              </li>
              <li>
                <a>About Us</a>
                <ul className="p-2">
                  <li>
                    <Link to={"/about-us"}>About Us</Link>
                  </li>
                  <li>
                    <Link to={"/executive-team"}>Executive Team</Link>
                  </li>
                  <li>
                    <Link to={"/project-delivery"}>Project Delivery</Link>
                  </li>
                  <li>
                    <Link to={"/regulations"}>Regulations</Link>
                  </li>
                  <li>
                    <Link to={"/hse-policy"}>HSE Policy</Link>
                  </li>
                  <li>
                    <Link to={"certifications"}>Certification</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to={"/services"}>Services</Link>
              </li>
              <li>
                <Link to={"/projects"}>Projects</Link>
              </li>
              <li>
                <Link to={"/contact"}>Contact</Link>
              </li>
            </ul>
          </div>
          <a className="hidden lg:flex">
            <FaSearch />
          </a>
        </div>
      </div>

      {/* ******************** */}

      <div
        className={`${
          active === true ? "fixed top-0 left-0 z-50 w-full" : ""
        } transition ease-in-out  text-white duration-300  bg-blue-950`}
      >
        <ul className="menu menu-horizontal text-lg font-light py-0 justify-center hidden lg:flex  z-20">
          <li className="border-l px-12 text-base font-semibold py-2 border-gray-600">
            <Link className={`${
          isActivePath("/") ? "text-red-500" : "" // Change colors based on pathname
        }`} to={"/"}>Home</Link>
          </li>
          <li>
            <details className="border-l px-12 text-base font-semibold py-2 border-gray-600 z-30">
              <summary>About Us</summary>
              <ul className="p-2 w-64 text-gray-700">
                <li>
                  <Link className={`${
          isActivePath("/about-us") ? "text-red-500" : "" 
        }`} to={"/about-us"}>About Us</Link>
                </li>
                <li>
                  <Link className={`${
          isActivePath("/executive-team") ? "text-red-500" : "" 
        }`} to={"/executive-team"}>Executive Team</Link>
                </li>
                <li>
                  <Link className={`${
          isActivePath("/project-delivery") ? "text-red-500" : "" 
        }`} to={"/project-delivery"}>Project Delivery</Link>
                </li>
                <li>
                  <Link className={`${
          isActivePath("/regulations") ? "text-red-500" : "" 
        }`} to={"/regulations"}>Regulations</Link>
                </li>
                <li>
                    <Link className={`${
          isActivePath("/hse-policy") ? "text-red-500" : "" 
        }`}  to={"/hse-policy"}>HSE Policy</Link>
                  </li>
                <li>
                  <Link className={`${
          isActivePath("/certifications") ? "text-red-500" : "" 
        }`} to={"/certifications"}>Certification</Link>
                </li>
              </ul>
            </details>
          </li>
          <li className="border-l px-12 text-base font-semibold py-2 border-gray-600">
            <Link className={`${
          isActivePath("/services") ? "text-red-500" : "" 
        }`} to={"/services"}>Services</Link>
          </li>
          <li className="border-l px-12 text-base font-semibold py-2 border-gray-600">
            <Link className={`${
          isActivePath("/projects") ? "text-red-500" : "" 
        }`} to={"/projects"}>Projects</Link>
          </li>
          <li className="border-l border-r px-12 text-base font-semibold py-2 border-gray-600">
            <Link className={`${
          isActivePath("/contact") ? "text-red-500" : ""
        }`} to={"/contact"}>Contact</Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
